<template>
  <div class="wrap">
    <div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in config" :key="index" :label="item.name" :name="index + ''">
          <div v-if="config[index] && flowInfoIng.index">
            <sqb-form
              ref="sqbForm"
              :formData="formModal.children[index]"
              :configs="config[index].config"
              :formIndex="index"
              :disabled="disabled"
              @getProcessData="getProcessData"
              :getAllTableColumnConfig="getAllTableColumnConfig"
            ></sqb-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="footer text-center">
        <el-button type="primary" @click="prev" class="next">上一步</el-button>
        <el-button type="primary" @click="validatorForm('saveApplyData')" class="save">保存申请</el-button>
        <el-button type="primary" @click="validatorForm('next')" class="next">下一步</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import applicantQlxxService from '@/pages/iebdc/api/apply/applicant-qlxx';
import applicantSqrService from '@/pages/iebdc/api/apply/applicant-sqr';
import { mapMutations, mapState } from 'vuex';
import { sqbConfig } from '@public/applyConfig';
import processInfo from '@iebdc/mixin/getProcessInfo';
// import { config } from 'vue/types/umd';
import SignAuthent from '../new-application/sign-authent';
import authorizedCertify from '../authorizedCertify';
export default {
  name: 'applicantTable',
  mixins: [processInfo],
  components: {
    // PreviewTable,
    // Questionnaire,
    // QuestionnaireDz,
    // GetContract,
    SignAuthent,
    authorizedCertify,
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    // 我要申请流程数据
    ...mapState('wysq-data', ['flowInfoIng']),
    // 表单数据
    ...mapState('wysq-data', ['processData']),
    ...mapState('wysq-step', ['activeIndex', 'currentSelecte']),
    ...mapState('taxStatus', ['taxStatus', 'beianStatus']),
    // 申请弹窗的title数据
    getAllTableColumnConfig() {
      const allTableColumnConfig = [];
      this.config.map((singleFlowConfig) => {
        singleFlowConfig.config.map((item) => {
          if (item.formConfig) {
            allTableColumnConfig.push(item.tableTitle);
          }
        });
      });
      return [...new Set(allTableColumnConfig)];
    },
  },
  data() {
    return {
      activeName: '0',
      disabled: false,
      lcdm: '',
      formModal: { children: [] },
    };
  },
  methods: {
    // /**
    //  * @desc vuex共用方法
    //  * */
    // // 更新流程信息
    ...mapMutations('wysq-data', ['setFlowInfoIng']),
    //设置表单指
    ...mapMutations('wysq-data', ['setFormData']),
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),

    /**
     * @desc 获取数据
     * */
    //根据ywh获取流程数据
    getProcessData() {
      applicantSaveService.getProcessInfo({ wwywh: this.flowInfoIng.wwywh }).then((res) => {
        if (res.success) {
          this.lcdm = res.data.netFlowdefCode;
          this.disabled = res.data.step === 'VERIFICATION' || res.data.step === 'VERIFYSUCCESS' ? true : false;
          applicantQlxxService.setQlxxEx(res.data);
          res.data.children = res.data.children || [{}];
          console.log('getProcessData', this.processData);
          res.data.children.forEach((item, pindex) => {
            this.processData.forEach((pitem, index) => {
              if (pindex === index) {
                item.tzrxxes = pitem.tzrxxes;
                if (!item.hasOwnProperty('qlxxExMhs')) {
                  item.qlxxExMhs = [{}];
                }
                for (let key in item) {
                  if (key === 'qlxxExMhs' && res.data.sqrlx === 'NORMAL') {
                    item[key] = pitem[key] ? pitem[key] : item[key];
                  } else if (key !== 'qlrs' && key !== 'ywrs' && key !== 'qlxxExMhs') {
                    item[key] = pitem[key] ? pitem[key] : item[key];
                    // console.log(key);
                    // if(key===)
                  }
                }
              }
            });
          });
          this.setFormData(res.data.children);
          this.formModal = res.data;
          // console.log('formModal', this.formModal);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上一步
    prev() {
      this.$router.push({
        name: this.prevItem.name,
        query: 1,
      });
    },
    // 保存申请数据
    async saveApplyData() {
      console.log(this.lcdm);
      console.log('formModel', this.formModel);
      sqbConfig.forEach((config) => {
        if (config.code === this.lcdm) {
          for (let i = 0; i < this.processData.length; i++) {
            this.processData[i].lcdm = config.data[i].code;
          }
          //   console.log('==修改流程代码信息成功==，修改后的流程信息为', this.processData);
        }
      });
      const params = {
        wwywh: this.flowInfoIng.wwywh,
        qlxxChildDtoList: this.processData,
      };
      if (params.qlxxChildDtoList[0].lcdm === 'N200104') {
        params.qlxxChildDtoList[0].qlxxEx.registerType = '8'; //存量房转移
      }
      // 调用修改接口
      const { success, message } = await applicantSaveService.modifyProcessInfo(params);
      if (success) {
        this.$message.success('保存成功');
      } else {
        this.$message.error(message);
      }
    },
    /**
     *  @desc 路由行为
     * */
    // 跳转到下一页面
    next() {
      this.targetToStep('填写申请表');
      this.saveApplyData().then(() => {
        this.$router.push({
          name: this.nextItem.name,
        });
      });
    },
    // /** 工具函数
    //  * */
    // 校验当前页面信息
    validatorForm(funcName) {
      //判断是否所有申请人表格填写完成
      const sqrTableValidator = new Promise((resolve, reject) => {
        const message = applicantSqrService.sqrTableValidate(this.formModal, this.config);
        if (message) {
          reject(message);
        }
        resolve();
      });
      //判断不动产权信息是否填写完成
      // const qlxxTableValidator = new Promise((resolve, reject) => {
      //   const message = applicantQlxxService.qlxxTableValidate(this.formModal, this.config);
      //   if (message) {
      //     reject(message);
      //   }
      //   resolve();
      // });
      //申请人表单验证
      const sqrCommonValidate = new Promise((resolve, reject) => {
        let numTzr = 0;
        let numSqr = 0;
        this.$refs.sqbForm.forEach((item) => {
          item.$refs.sqr.forEach((form) => {
            form.$refs &&
              form.$refs.sqr &&
              form.$refs.sqr.forEach((sqrform) => {
                sqrform.$refs.form.validate((valid) => {
                  if (!valid) {
                    numSqr++;
                  }
                });
              });
            form.$refs.tzrform &&
              form.$refs.tzrform.$refs.form.validate((valid) => {
                if (!valid) {
                  numTzr++;
                }
              });
          });
          if (numTzr > 0) {
            reject('请完善通知人信息');
            return;
          }
          if (numSqr > 0) {
            reject('请完善申请人信息');
            return;
          }
        });
        resolve();
      });

      //权利信息表单校验
      const qlxxExMhsValidator = new Promise((resolve, reject) => {
        let num = 0;
        this.$refs.sqbForm.forEach((item) => {
          item.$refs.sqr.forEach((form) => {
            form.$refs &&
              form.$refs.sqr &&
              form.$refs.sqr.forEach((sqrform) => {
                sqrform.$refs.form.validate((valid) => {
                  if (!valid) {
                    num++;
                  }
                });
              });
            form.$refs.tzrform &&
              form.$refs.tzrform.$refs.form.validate((valid) => {
                if (!valid) {
                  num++;
                }
              });
          });
          // item.$refs.qlxxExMhs.forEach((form) => {
          //   form.$refs.form.validate((valid) => {
          //     if (!valid) {
          //       num++;
          //     }
          //   });
          // });
          // if (num > 0) {
          //   reject('请完善不动产申请信息');
          //   return;
          // }
        });
        resolve();
      });

      //权利信息扩展表单校验
      const qlxxValidator = new Promise((resolve, reject) => {
        let num = 0;
        this.$refs.sqbForm.forEach((item) => {
          (item.$refs.qlxx || []).forEach((form) => {
            form.$refs.form.validate((valid) => {
              if (!valid) {
                num++;
              }
            });
          });
          if (num > 0) {
            reject('请完善不动产申请信息');
            return;
          }
        });
        resolve();
      });

      //通知人表单验证
      // const tzrValidator = new Promise((resolve, reject) => {
      //   let num = 0;
      //   this.$refs.sqbForm.forEach((item) => {
      //     item.$refs.sqr.forEach((form) => {
      //       form.$refs.form.$refs.form.validate((valid) => {
      //         if (!valid) {
      //           num++;
      //         }
      //       });
      //     });
      //     if (num > 0) {
      //       reject('请完善通知人信息');
      //       return;
      //     }
      //   });
      //   resolve();
      // });
      //校验内容
      let valid = [];
      if (this.flowInfoIng.sqrlx === 'NORMAL') {
        valid = [qlxxValidator, sqrCommonValidate, sqrTableValidator];
      } else {
        // valid = [qlxxValidator, sqrCommonValidate, qlxxExMhsValidator, sqrTableValidator];
        valid = [qlxxValidator, sqrCommonValidate, sqrTableValidator];
      }
      // //校验开始
      Promise.all(valid)
        .then(() => {
          // 校验成功,调用相应方法
          this[funcName]();
        })
        .catch((message) => {
          this.$message.error(message);
        });
    },
    //切换表单
    handleClick() {
      // 需要更新的数据对象
      let params = Object.assign({}, this.flowInfoIng);
      params.ywh = this.formModal.ywh[this.activeName];
      params.index = this.activeName;
      // 调用vuex，更新数据对象
      this.setFlowInfoIng(params);
    },
    //更新流程中数据
    updateFlowInfoIng(data) {
      // 需要更新的数据对象
      const params = {
        id: data.id,
        wwywh: data.wwywh,
        ywh: data.ywh[this.activeName],
        index: this.activeName,
        subcode: data.subFlowcode,
        subname: data.subFlowname,
        code: data.netFlowdefCode,
        sqrlx: data.sqrlx,
      };
      // 调用vuex，更新数据对象
      this.setFlowInfoIng(params);
      this.setFormData(data.children);
    },
    logInfo() {
      console.info('[填写申请表组件 START] --------------------------------');
      console.info('this.config ', this.config);
      console.info('this.formModal ', this.formModal);
      console.info('this.activeName ', this.activeName);
      console.info('this.flowInfoIng（渲染申请表v-if条件） ', this.flowInfoIng);
      console.info('this.getAllTableColumnConfig ', this.getAllTableColumnConfig);
      console.info('[填写申请表组件 END] ----------------------------------');
    },
  },
  mounted() {},
  created() {
    this.setFlowInfoIng({});
    this.setFormData([]);
    let ywhLocal = localStorage.getItem('ywh');
    let ywh = this.$route.params.ywh ? this.$route.params.ywh : ywhLocal;
    // 获取流程数据
    this.getProcessInfo(ywh)
      .then((res) => {
        this.updateFlowInfoIng(this.formModal);
        this.lcdm = this.formModal.netFlowdefCode;
        let code = this.formModal.netFlowdefCode;
        this.$store.commit('wysq-step/getStepList', {
          code: code,
          step: this.formModal.step,
        });
        if (this.formModal.beianStatus === '1') {
          this.currentSelecte.forEach((data, index) => {
            if (data.name === 'authorizedCertify') this.currentSelecte.splice(index, 1);
          });
        }
        if (
          (this.formModal.step === 'VERIFICATION' || this.formModal.step === 'VERIFYSUCCESS') &&
          !this.$route.params.prev &&
          this.$hasPermission('IEBDC:SQ:SQRZ') &&
          this.formModal.beianStatus === '1'
        ) {
          this.$router.push({
            name: 'authorizedCertify',
          });
        } else {
          this.targetToStep('填写申请表');
        }
        if (Array.isArray(this.formModal.children[0].qlxxExMhs) && this.formModal.children[0].qlxxExMhs.length > 0) {
          const _qlxxExMhs = this.formModal.children[0].qlxxExMhs;
          // 宗地代码根据单元号赋值
          if (!_qlxxExMhs[0].zddm) {
            _qlxxExMhs[0].zddm = _qlxxExMhs[0].bdcdyh.slice(0, 19);
          }
          // 产权核验土地使用结束时间赋值
          if (_qlxxExMhs[0].syqjssj) {
            _qlxxExMhs.tdsyqjssj = new Date(_qlxxExMhs[0].syqjssj).getTime();
          }
          // 土地使用权人解密
          if (_qlxxExMhs[0].tdsyqr) {
            _qlxxExMhs[0].tdsyqr = this.$decryptByDES(_qlxxExMhs[0].tdsyqr);
          }
        }
        // 打印信息
        this.logInfo();
      })
      .catch((err) => {
        console.log(err);
        this.$message.error('获取数据失败');
      });
    // // 申请表内流程
    // // isShowType为空显示申请表
    // this.$bus.$on('sfrzToPre', () => {
    //   const { ywh } = this.$route.params;
    //   this.isShowType = '';
    //   this.getProcessInfo(ywh);
    // });
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';

.wrap {
  border-radius: $border-radius-base;
  background-color: $background-color-layout;
  /deep/.footer {
    margin-top: $spacing-large;
    .save {
      background: #0a69f3;
      border: 1px solid #0a69f3;
      display: inline-block;
      width: 198px;
      height: 38px;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      margin-right: 30px;
      text-align: center;
      outline: none;
      &:hover {
        background: #00aeff;
        border: 1px solid #00aeff;
      }
    }
    .next {
      display: inline-block;
      width: 198px;
      height: 38px;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      outline: none;
      background: #ff7f1a;
      border: 1px solid #ff7f1a;
      margin-right: 0;
      &:hover {
        background: #ec6a42;
        border: 1px solid #ec6a42;
      }
    }
  }
}
</style>
